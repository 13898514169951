<template>
  <div class="logo-box">
    <v-img
      alt="EdReserve Logo"
      class="shrink"
      contain
      :src="logoColor == 'white' ? whiteLogo : brandLogo"
      transition="scale-transition"
      :width="width"
    />
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    logoColor: {
      type: String,
      default: "white",
    },
    width: {
      type: Number,
      default: 120,
    },
  },
  data: () => {
    return {
      whiteLogo: require("../assets/logo-white.svg"),
      brandLogo: require("../assets/logo.svg"),
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
