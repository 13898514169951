<template>
  <v-app>
    <v-app-bar app color="#9B51E0" dark>
      <v-container>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <Logo logoColor="white" />
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-container v-if="error">
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            {{ error }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            {{ error2 }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn @click="back()" color="#9B51E0" class="white-text"
              ><div class="white-text">Back</div></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!error">
        <v-row>
          <v-col>
            <Logo logoColor="brand" :width="200" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            A new way to request
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            classes for future terms
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-card dense color="#9B51E0">
              <v-card-text>
                <div class="white-text">Let's get you logged in</div>
              </v-card-text>
              <v-card-text
                ><div class="white-text">
                  What is your school email address?
                </div></v-card-text
              >
              <v-container>
                <v-row no-gutters>
                  <v-col style="background: white;">
                    <v-text-field
                      hide-details
                      class="pa-2"
                      color="gray"
                      v-model="email"
                      hint="Enter your school email to get started"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn @click="getStarted()" color="#9B51E0" class="white-text"
              ><div class="white-text">Get Started</div></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<style scoped>
.white-text {
  color: white;
}
</style>
<script>
import Logo from "./components/Logo";
import { getDomain, getSchoolAppDomain } from "@/utils";
import { getSchool, removeSchool, setSchool } from "./utils";
import _ from "lodash";

import logdown from "logdown";
const logger = logdown("App");

export default {
  name: "App",

  components: {
    Logo,
  },

  data: () => ({
    email: "",
    error: "",
    error2: "",
  }),

  methods: {
    back() {
      this.error = "";
      this.error2 = "";
    },
    getStarted() {
      logger.debug(`getStarted, email: ${this.email}`);
      const school = getDomain(this.email);
      if (!school || school == "") {
        this.error = `Invalid email`;
        this.error2 = `${this.email}`;
        logger.debug(`getStarted, invalid email: ${this.email}`);
      } else {
        const schoolAppDomain = getSchoolAppDomain(school);
        if (!schoolAppDomain || schoolAppDomain == "") {
          this.error = `EdReserve is not yet available at:`;
          this.error2 = `${school}`;
          logger.debug(`getStarted, unsupported school: ${school}`);
        } else {
          logger.debug(`app domain: ${schoolAppDomain}`);
          setSchool(schoolAppDomain);
          window.location = this.formatLocation(schoolAppDomain);
        }
      }
    },
    formatLocation(domain) {
      return `https://${domain}/start-ext/`;
    },
  },

  created() {
    const domain = getSchool();
    let params = new URL(document.location).searchParams;
    let clear = params.get("clear");
    if (clear) {
      removeSchool();
    } else if (typeof domain == "string" && _.size(domain) > 0) {
      window.location = this.formatLocation(domain);
    }
  },
};
</script>
