function getDomain(email){
    const parts = (""+email).split("@")
    if(parts.length == 2){
        return parts[1].toLowerCase().trim()
    }
}

function getSchoolAppDomain(schoolDomain){
    const domains = {
        'usi.edu': 'usi.edreserve.app',
        'iup.edu': 'iup.edreserve.app',
        'sinclair.edu': 'sinclair.edreserve.app',
    }
    return domains[schoolDomain]
}

const xEdreserveSchoolKey = 'x-edreserve-school'

function getSchool(){
    return localStorage.getItem(xEdreserveSchoolKey)
}

function setSchool(school){
    localStorage.setItem(xEdreserveSchoolKey, school)
}

function removeSchool(){
    localStorage.removeItem(xEdreserveSchoolKey)
}

export{
    getDomain,
    getSchoolAppDomain,
    getSchool,
    setSchool,
    removeSchool,
}